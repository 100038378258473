function beforeImgUpload(file) {
    console.log(file);
    debugger
    const isLt5M = file.size / 1024 / 1024 < 50;
    if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 50M!");
        return false
    }
    // 图片大小大于2M进行图片压缩
    if (file.size / 1024 / 1024 > 2) {
        if (file.name.endsWith(".mp4")) {
            // mp4不压缩
            return true;
        }
        const quality = 0.5
        return new Promise(resolve => {
            const reader = new FileReader()
            const image = new Image()
            image.onload = (imageEvent) => {
                const canvas = document.createElement('canvas') // 创建画布
                const context = canvas.getContext('2d') // 画布为2d
                const width = image.width * quality // 图片宽度 * 压缩比例
                const height = image.height * quality // 图片高度 * 压缩比例
                canvas.width = width // 画布宽度
                canvas.height = height // 画布宽度
                context.clearRect(0, 0, width, height)
                context.drawImage(image, 0, 0, width, height)
                const dataUrl = canvas.toDataURL(file.type) //图片转路径
                const blobData = dataURLtoBlob(dataUrl, file.type) //图片转二进制
                resolve(blobData)
            }
            reader.onload = e => { image.src = e.target.result }
            reader.readAsDataURL(file)
        })
    } else {
        return true
    }
}
//图片转二进制
function dataURLtoBlob(dataURL, type) {
    var binary = atob(dataURL.split(',')[1])
    var array = []
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
    }
    return new Blob([new Uint8Array(array)], { type: type })
}

// 将四个全局公共方法，组合成一个对象，并暴露出去
export default {
    beforeImgUpload,
    dataURLtoBlob
}