<template>
  <el-container class="home-container">
    <el-header :style="{height: menuNormal? '60px': '0px'}">
      <div class="left">
        <h3 class="title">{{systemName}}</h3>
        <h6 class="store-name" v-if="storeName">—— {{ storeName }}</h6>
      </div>
      <div class="right">
        <div class="infoBell" @click="showInfo">
          <i class="el-icon-bell"></i>
          <div class="infoCount" v-if="titleIndex==0 && infoTotal>0">
            {{infoTotal}}
          </div>
        </div>
        <transition name="el-fade-in-linear">
          <el-card class="info-card" v-show="IsShowInfo" >
            <div class="infoHead">
              系统消息
              <i class="el-icon-bell"></i>
              <i class="el-icon-close colse" @click="closeInfo"></i>
            </div>
            <div class="titleBox">
              <div class="titleItem"  @click="changeTitle(index)" :class="{ titleActive: titleIndex==index
              }" v-for="(item,index) in infoLabel" :key="index">
                <span>{{ item }}</span>
                <!-- <div class="infoCount position">
                  {{ index }}
                </div> -->
              </div>
            </div>
            <div class="infoContent" v-if="infoList.length >0">
              <div v-for="info in infoList" class="itemInfo" >
                <div>
                  <div style="font-size: 14px;color: #303133"> {{ info.title }}</div>
                  <div>
                    <span :class="[info.readStatus?'readClass':'noread']">{{ info.readStatus?'已读':'未读' }}</span>
                  </div>
                </div>
                <pre>{{ info.content }}</pre>
                <div class="read">
                  <div v-if="info.h5Path" class="blue" @click="toDetails(info.h5Path)">查看详情</div>
                  <div v-if="!info.readStatus" class="red" @click="readMsg(info.id)">已&nbsp;读</div>
                </div>
              </div>
            </div>
            <div class="noData" v-else>暂无消息~</div>
            <div class="read" style="padding-right: 20px;">
                <el-pagination
              v-if="infoList.length > 1"
              style="margin-top:10px"
              :current-page="infoForm.pageNumber"
              :page-size="infoForm.pageSize"
              @current-change="handleCurrentChange"
                small
                layout="prev, pager, next"
                :total="infoTotal">
              </el-pagination>
              <div class="red" v-if="infoList.length>0 && titleIndex==0" @click="readMsgList()">一键已读</div>
            </div>

          </el-card>
        </transition>

        <el-dropdown trigger="click">
          <span class="el-dropdown-link" @click="closeInfo">
            {{ userName }}，你好！<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="toNew">操作手册</el-dropdown-item>
            <el-dropdown-item divided @click.native="$router.push('/change-password')"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item
              v-if="belongStores.length > 1"
              divided
              @click.native="cutStore"
              >切换门店</el-dropdown-item
            >
            <el-dropdown-item divided @click.native="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container style="position: relative;background: #eaedf1;">
      <el-aside class="aside" :style="{width: menuNormal? '226px': '20px', 'z-index': 1}">
        <div v-if="menuNormal" style="background: #333744;height: 100%;">
          <el-scrollbar style="height: 100%;">
            <el-menu style="overflow: hidden"
                     background-color="#333744"
                     text-color="#999"
                     active-text-color="#409EFF"
                     :collapse-transition="false"
                     router
                     :default-active="$route.path"
                     :default-openeds="openeds"
                     @close="handleClose"
                     @select="handleSelect"
                     ref="menus"
            >
              <!-- 一级菜单 -->
              <el-submenu :index="item.id + ''" v-for="item in menuList" :key="item.id">
                <!-- 一级菜单模板 -->
                <template slot="title">
                  <!-- 图标 -->
                  <i :class="item.icon"></i>
                  <!-- 文本 -->
                  <span class="first-name">{{ item.moduleName }}</span>
                </template>
                <!-- 二级子菜单 -->
                <el-menu-item
                  :index="subItem.frontRouter"
                  v-for="subItem in item.children"
                  :key="subItem.id"
                  @click="saveNavState(subItem.frontRouter)"
                >
                  <!-- 二级菜单模板 -->
                  <template slot="title">
                    <!-- 文本 -->
                    <span>{{ subItem.moduleName }}</span>
                  </template>
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </el-scrollbar>
        </div>
        <div class="btn" @click="menuNormal = !menuNormal">
          <el-icon :class="menuNormal? 'el-icon-d-arrow-left': 'el-icon-d-arrow-right'"/>
        </div>
      </el-aside>
      <el-main class="elMain" style="padding-left: 0;position: relative;" id="elMain">
        <el-scrollbar v-show="!isWelcome" style="height: 100%;">
          <div :style="{height: menuNormal? 'calc(100vh - 110px)': 'calc(100vh - 40px)'}" class="el-main-container-parent">
            <router-view></router-view>
          </div>
        </el-scrollbar>
      </el-main>
      <div v-if="isWelcome" style="position: absolute;;height: 100%;right: 0;" :style="{width: menuNormal? 'calc(100% - 200px)': '100%'}">
        <img :src="backgroundImage" alt="" style="width: 100%;height: 100%" >
      </div>

    </el-container>

    <el-dialog
      title="切换门店"
      :visible.sync="dialogVisible"
      width="400px"
      ref="addFormRef"
      :before-close="handleClose"
    >
      <el-radio-group v-model="radio" class="radio-box">
        <el-radio
          class="radio"
          v-for="item in belongStores"
          :key="item.id"
          :label="item.id"
          >{{ item.storeName }}</el-radio
        >
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitStore">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import config from "@/assets/config";

export default {
  inject: ["reload"],
  watch: {
    $route: {
      handler() {
        this.isWelcome = "/welcome" === this.$route.path;
      }
    }
  },
  data() {
    return {
      systemName: "",
      serviceName: "",
      backgroundImage: "",
      // 是否是首页
      isWelcome: true,
      // 是否正常显示菜单
      menuNormal: true,
      active: "",
      menuList: [],
      userName: "",
      storeName: "",
      activePath: "",
      openeds: [],
      roleId: "",
      belongStores: [],
      dialogVisible: false,
      radio: "",
      userData: null,
      infoLabel:['未读','已读'],
      titleIndex:0,
      IsShowInfo:false,
      infoForm:{
        storeIdList:[],//后端用，用户数据权限范围
        pageSize:4,
        pageNumber:1,
        searchKey:"",
        searchType:"",
        createdId:"",
        startTime:"",
        endTime:"",
        status:0,
        storeId:"",
        category:""
      },
      infoList:[],
      infoTotal:0,
    };
  },
  created() {
    this.systemName = config.systemName;
    this.serviceName = config.serviceName;
    this.backgroundImage = config.pictureSetting.homeMainBackground;
    this.isWelcome = "/welcome" === this.$route.path;
    this.active = this.$route.path;
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.userData = userData;
    this.userName = userData.userName;
    this.storeName = userData.storeName;
    this.roleId = userData.roleId;
    this.belongStores = userData.belongStores || [];
    this.getMenu();
    this.activePath = window.sessionStorage.getItem("activePath");
    this.getInfoList()
  },
  methods: {
    //　当前页发生改变
    handleCurrentChange(newPage) {
      this.infoForm.pageNumber = newPage;
      this.getInfoList();
    },
    changeTitle(index){
      this.titleIndex = index;
      this.infoForm.status = index
      this.infoForm.pageNumber = 1
      this.getInfoList()
    },
    showInfo(){
      this.IsShowInfo = true;
    },
    closeInfo(){
      this.IsShowInfo = false;
      this.infoForm.status = 0;
    },
    readMsg(id){
      this.readMessage(id)
      this.getInfoList()
    },
    readMsgList(id){
      this.readMessageList()
      this.getInfoList()
    },
    toDetails(url){
      this.$router.push(url);
      this.closeInfo();
    },
    async getInfoList() {
      const { data: res } = await this.$http.get(this.URL.queryMyMessages,{params:this.infoForm});
      if (res.code != 2000) return this.$message.error(res.message);
      this.infoList =  res.data && res.data.records;
      this.infoForm.pageNumber =  res.data && res.data.pageNumber;
      this.infoForm.pageSize =  res.data && res.data.pageSize;
      this.infoTotal =  res.data && res.data.totalCount;
    },
    async readMessage(id) {
      const { data: res } = await this.$http.get(this.URL.readMessage + "?id="+id);
      if (res.code != 2000) return this.$message.error(res.message);
    },
    async readMessageList() {
      let query = []
      this.infoList.map((item)=>{
        console.log(item)
        query.push(item.id)
      })

      const { data: res } = await this.$http.post(this.URL.readMessage,query);
      if (res.code != 2000) return this.$message.error(res.message);
    },
    async getMenu() {
      const { data: res } = await this.$http.get(this.URL.menu + "?menuType=0");
      if (res.code != 2000) return this.$message.error(res.message);
      this.menuList = res.data;
      this.openeds = res.data.map((item) => item.id + "");
      localStorage.setItem("menu", JSON.stringify(res.data));
    },
    async logout() {
      const { data: res } = await this.$http.delete(this.URL.logout);
      if (res.code != 2000) return this.$message.error(res.message);
      this.$message.success(res.data);
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push("/login");
    },
    handleClose(key, keyPath) {
      this.$refs.menus.open(keyPath);
    },
    // 高亮
    saveNavState(activePath) {
      // 将获取的路径存储
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
    },
    handleSelect(key) {
      sessionStorage.clear();
    },
    toNew() {
      let routeUrl = this.$router.resolve({
        path: "/handbook",
        query: {
          id: this.roleId,
        },
      });
      window.open(routeUrl.href, "_blank"); //关键在此
    },
    cutStore() {
      this.radio = JSON.parse(localStorage.getItem("userData")).storeId;
      this.dialogVisible = true;
    },
    async submitStore() {
      const { data: res } = await this.$http.get(this.URL.switchMyStore, {
        params: { storeId: this.radio },
      });
      if (res.code != 2000) return this.$message.error(res.message);
      this.$message.success("切换成功！");
      localStorage.setItem("userData", JSON.stringify(res.data));

      // const data = { ...this.userData };
      // const store = this.belongStores.filter(
      //   (item) => item.id == this.radio
      // )[0];
      // data.storeName = this.storeName = store.storeName;
      // data.storeId = store.id;
      // localStorage.setItem("userData", JSON.stringify(data));
      this.dialogVisible = false;

      this.reload();
    },
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      done();
      // })
      // .catch((_) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.radio {
  display: block;
  margin: 10px;
}
.left {
  display: flex;
  .store-name {
    margin-left: 20px;
    // font-size: 16px;
    // font-weight: 700;
  }
}
.home-container {
  height: 100% !important;
  overflow: hidden;
}
.el-header {
  // background-color: rgb(172, 171, 171);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  img {
    height: 50px;
  }
  .el-dropdown-link {
    cursor: pointer;
  }
}
.el-container {
  height: calc(100% - 60px);
}
.el-aside {
  width: 206px;
  background-color: #333744;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #a3a2a2;
  }
  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #eaedf1;
}
/deep/.el-submenu {
  font-size: 14px;
  .el-submenu__icon-arrow {
    display: none;
  }
  .el-submenu__title {
    padding: 0;
    padding-left: 5px !important;
    height: 43px;
    line-height: 43px;
    i {
      margin-right: 2px;
    }
  }
  .el-menu {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5px;
    padding-left: 30px;
  }
  .el-menu-item {
    width: 48%;
    height: 33px;
    line-height: 33px;
    min-width: 70px;
    font-size: 13px;
    padding: 0;
    padding-left: 0 !important;
    background-color: transparent !important;
  }
}

.el-dropdown-menu__item {
  min-width: 100px;
  text-align: center;
}

.toggle-button {
  background-color: #4a5064;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  // letter-spacing: 0.2em;
  cursor: pointer;
  padding: 5px 0;
}

.first-name {
  font-weight: 700;
  color: #fff;
}
.right{
  display: flex;
  align-items: center;
  .infoCount{
    text-align: center;
    position: absolute;
    right:4px;
    top:-3px;
    width: 14px;
    height: 14px;
    background-color: rgba(228, 36, 36, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 10px;
    border-radius: 50%;
  }
  .infoBell{
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    width: 30px;
    i{
      font-size: 22px;
    }
  }
  /deep/.el-card{
    min-height: 20px;
    height: auto;
  }
  /deep/.el-card__body{
    padding: 0;
    height: auto;
  }
  .info-card{
    position: absolute;
    width: 410px;
    min-height: 80px;
    top:50px;
    right: 0;
    z-index: 9999;
    background-color: #f7f7f7;
    .infoHead{
      height: 30px;
      text-align: center;
      font-size: 14px;
      line-height: 30px;
      background: #c9c9c9;
      border-bottom: 1px solid rgb(224, 224, 224);
      .colse{
        position: absolute;
        right: 20px;
        color: white;
        top: 8px;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .noData{
      font-size: 14px;
      text-align: center;
      line-height: 60px;
    }
    .infoContent{
      font-size: 14px;
      .itemInfo{
        border-bottom: 1px solid #dddbdb;
        padding: 10px 24px;
        font-size: 12px;
        color: #666;
        // cursor: pointer;
        >div:nth-child(1){
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;
        }
        .readClass{
          color: #d1cccc;
        }
        .noread{
          color:#878a91;
        }
        .blue{
          color:#409EFF;
          margin-top: 5px;
          cursor: pointer;
        }

      }
      .itemInfo:hover{
        background-color: #F2F6FC;
      }
    }
    .read{
      display:flex;
      justify-content: space-between;
      align-items: center;
      .red{
        color: #999;
        cursor: pointer;
        font-size: 12px;
        border: 1px solid #666;
        border-radius: 4px;
        padding: 3px 5px;
        }
    }

    .titleBox{
      display: flex;
      .titleItem{
        position: relative;
        padding: 10px;
        text-align: center;
        font-size: 10px;
        cursor: pointer;
        background-color: #c9c9c9;
        color: #333744;
        width: 50%;
        .position{
          right: 1px;
          top: 5px;
        }
      }
      .titleActive{
        background-color: #8a8888;
        color: white;
      }
    }
  }
}
.elMain{

  background-size: calc(100% - 40px) calc(100% - 50px);
  background-repeat: no-repeat;
  background-position: 20px 20px;
}
.elMain /deep/.el-scrollbar__wrap{
  overflow-x: hidden;
}
.el-aside /deep/.el-scrollbar__wrap{
  overflow-x: hidden;
}
.aside{
  transition: all .3s;
  position: relative;
  padding-right: 20px;
  background: transparent;
  color: #999;
  .btn{
    position: absolute;
    top: 50%;
    right: 3px;
    background: #FFF;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    border: 1px solid #afb4ba;
    border-left: none;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0 6px 6px 0;
    display: none;
  }
}
.aside:hover{
  color: #409EFF;
  .btn{
    display: block;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  }
}
/deep/.el-main-container-parent>.el-card{
  height: 100%;
}
</style>
