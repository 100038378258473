<template>
  <div class="welcome">

  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  created(){
    window.sessionStorage.setItem("activePath", "");
  }
};
</script>

<style lang="less" scoped>
.welcome{
  position: relative;
  height: 100%;
  h1{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 35px;
  }
  img{
    width: calc(100%);
    height: calc(100vh - 90px);
    position: absolute;
    top: -20px;
    left: -20px;
  }
}
</style>
