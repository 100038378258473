<template>
  <!-- 分页数据 -->
  <div style="padding-top:10px;display: flex;justify-content: space-between;" class="form-page" :id="id">
    <el-pagination
        background
        :layout="layout"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size.sync="size"
        :current-page.sync="number"
        @current-change="pageChange"
        @size-change="sizeChange"
        :total="totalCount"
        small
    ></el-pagination>
    <slot></slot>
  </div>
</template>

<script>

export default {
  emits: ["onLoad"],
  name: "",
  props: {
    pageSize: {default: 10},
    pageNumber: {default: 1},
    totalCount: {default: 0},
    // 是否初始化完成立即进行查询
    startSearch: {default: true},
    layout: {default: "total, prev, pager, next, sizes"}
  },
  watch: {
    pageSize: {
      handler() {
        this.size = this.pageSize;
      }
    },
    pageNumber: {
      handler() {
        this.number = this.pageNumber;
      }
    }
  },
  components: {
  },
  model: {
  },
  data() {
    return {
      number: 1,
      size: 20,
      id: "",
    };
  },
  created() {
    if (this.pageSize) {
      this.size = this.pageSize;
    } else {
      this.$emit("update:pageSize", this.pageSize);
    }
    if (this.pageNumber) {
      this.number = this.pageNumber;
    } else {
      this.$emit("update:pageNumber", 1);
    }
    if (this.startSearch) {
      this.$emit("update:pageNumber", this.pageNumber);
      this.$emit("update:pageSize", this.pageSize);
      // 初始化完成立即进行查询
      this.$emit("onLoad");
    }
    this.id = "search_condition2" + (Math.random()*9999).toFixed(0);
  },
  mounted() {
    this.$nextTick().then(() => this.resetParentHeight());
  },
  methods: {
    resetSearch() {
      // 变更查询条件，重新发起分页查询
      this.$emit("update:pageNumber", 1);
      this.onLoad();
    },
    pageChange(pageNumber) {
      this.$emit("update:pageNumber", pageNumber);
      this.onLoad();
    },
    sizeChange(pageSize) {
      this.$emit("update:pageSize", pageSize);
      this.onLoad();
    },
    onLoad() {
      this.$emit("onLoad");
    },
    resetParentHeight() {
      if (this.$parent.addConditionHeight) {
        let docHeight = document.querySelector(`#${this.id}`).clientHeight;
        this.$parent.addConditionHeight(docHeight);
      }
    },
  }
};
</script>

<style lang="less" scoped>
.form-page ::v-deep .el-pagination{
  padding-left: 0;
  padding-right: 0;
  .el-pagination__sizes{
    margin-right: 0;
  }
}
.form-page ::v-deep .el-pagination .el-select .el-input .el-input__inner{
  height: 22px;
  line-height: 22px;
}
::v-deep .el-button{
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 3px;
}
</style>
