import commonApi from "@/api/commonApi";
export default{

  excelDown: (res, name = "模版文件.xlsx", type) => {
    let typ = type || "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    let blob = new Blob([res], { type: typ });
    let downloadElement = document.createElement("a"); //创建a标签
    let href = window.URL.createObjectURL(blob);
    downloadElement.href = href;
    downloadElement.download = name; //导出时的文件名 例：商品包管理.xlsx
    document.body.appendChild(downloadElement);
    downloadElement.click();   //触发点击事件
    document.body.removeChild(downloadElement);
    window.URL.revokeObjectURL(href);
  },
  // 清空
  clear: () => {
    sessionStorage.clear();
  },
  // 获取用户信息
  getUserInfo: () => {
    return JSON.parse(localStorage.getItem("userData"));
  },
  hasRight(user, url) {
    let right = 0;
    if ((user.leader === 1 || user.roleType === 0)) {
      right = 1
    }
    // 如果对这个功能有编辑权限
    let models = user.accessModules;
    for (let i = 0; i < models.length; i++) {
      if (models[i].uri && models[i].uri.indexOf(url) > -1) {
        // 找到
        let access = models[i].accessModel;
        if (access.length > 0 && access.charAt(1) > '0') {
          right = 1;
        }
        break;
      }
    }
    return right;
  },
  // 查询所有区域
  async getAreaList() {
    let cached = sessionStorage.getItem("areaList")
    if (!cached) {
      // 没有数据，获取数据并缓存
      const data = await commonApi.queryAreas();
      // 把获取到的data转成对象
      // 缓存数据
      sessionStorage.setItem("areaList", JSON.stringify(data))
      return data;
    } else {
      return JSON.parse(cached);
    }
  },
  // 查询所有门店
  async getStoreList() {
    let cached = sessionStorage.getItem("storeList")
    if (!cached) {
      // 没有数据，获取数据并缓存
      const data = await commonApi.queryStores();
      // 把获取到的data转成对象
      // 缓存数据
      sessionStorage.setItem("storeList", JSON.stringify(data))
      return data;
    } else {
      return JSON.parse(cached);
    }
  },
  // 查询所有职员
  async getEmployeeList() {
    let cached = sessionStorage.getItem("employeeList")
    if (!cached) {
      // 没有数据，获取数据并缓存
      const data = await commonApi.queryAllEmployeeInMyRange();
      // 把获取到的data转成对象
      // 缓存数据
      sessionStorage.setItem("employeeList", JSON.stringify(data))
      return data;
    } else {
      return JSON.parse(cached);
    }
  },
  // 获取所有的职员信息，只用于显示，不可用于其它
  getAllEmployee4Show() {
    return new Promise(resolve => {
      let key = "employee4Show";
      let cached = sessionStorage.getItem(key);
      // 有数据，直接返回
      if (cached) {
        resolve(JSON.parse(cached));
      } else {
        // 没有数据，查询接口
        commonApi.queryAllEmployee4Show().then(list => {
          sessionStorage.setItem(key, JSON.stringify(list));
          resolve(list);
        })
      }
    })
  },
  // 不同的请求，给定的url，即name，这个请求在同一时间只能发起一次，超过一次直接返回异常
  distinct(url, timeout) {
    return new Promise((resolve, reject) => {
      if (!timeout) {
        // 默认时间是1秒
        timeout = 1000;
      }
      let name = "distinctUrl:" + url;
      // 缓存的数据
      let ses = sessionStorage.getItem(name);
      if (!ses) {
        // 没有这个请求,执行请求,添加标记
        sessionStorage.setItem(name, new Date().getTime() + '');
        resolve();
      } else {
        // 有这个请求，是否超过了时间
        let pass = new Date().getTime() - ses;
        if (pass > timeout) {
          // 超过了时间，执行
          sessionStorage.setItem(name, new Date().getTime() + '')
          resolve();
        } else {
          // 等待下次执行
          reject();
        }
      }
    });
  },
  /**
   * 向光标所在的位置，插入文本，适用于el-input textarea
   * @param documentId 编辑元素的id，必须要指定id才行
   * @param str 需要添加什么文本
   */
  addExpressContent(documentId, str){
    let obj = document.getElementById(documentId);
    if (document.selection) {
      let sel = document.selection.createRange();
      sel.text = str;
    } else if (typeof obj.selectionStart === 'number' && typeof obj.selectionEnd === 'number') {
      let startPos = obj.selectionStart;
      let    endPos = obj.selectionEnd;
      let    cursorPos = startPos;
      let    tmpStr = obj.value;
      obj.value = tmpStr.substring(0, startPos) + str + tmpStr.substring(endPos, tmpStr.length);
      cursorPos += str.length;
      obj.selectionStart = obj.selectionEnd = cursorPos;
    } else {
      obj.value += str;
    }
  },
}
