<template>
  <!-- -->
  <div :id="id" style="height: 100%;" :style="{'--tabPanelHeight': allHeight + 'px'}">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "",
  components: {
  },
  data() {
    return {
      id: "",
      // 列表页面查询条件的高度
      conditionHeight: 0,
      // 页面本身的高度
      mainHeight: 500,
      allHeight : 0,
      ready: false,
      // 使用el-tabs时的panel
      divType: "list_page",
      timeOut: "",
      mainHeightChange: []
    };
  },
  unmounted() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  },
  created() {
    this.mainHeight = 500;
    this.ready = false;
    this.id = 'list-page' + (Math.random()*9999).toFixed(0);
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {

    },
    // 添加一个操作区域高度
    addConditionHeight(height) {
      // 将组件的高度，添加到list中
      this.allHeight += height;
      return true;
    },
    setConditionHeight(height) {
      this.conditionHeight = height;
    },
    // 接收一个回调参数，用于mainDiv变更
    setMainHeightObserver(callBack) {
      this.mainHeightChange.push(callBack);
    }
  }
};
</script>

<style lang="less" scoped>
@import "@/assets/css/table.scss";
::v-deep .el-tabs{
  height: 100%;
  .el-tabs__content{
    // 不能使用calc函数，会出错
    height: var(--tabPanelHeight);
    .el-tab-pane{
      height: 100%;
      overflow: auto;
    }
  }
}
</style>