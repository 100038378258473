import axios from "../util/axiosUtil";

export default {
  // 查询全部区域列表
  queryAreas:  () => axios.get({url: "/basedata/common/queryAreas"}),
  // 查询全部门店
  queryStores: () => axios.get({url: "/basedata/common/queryStores"}),
  //查询所有职员
  queryAllEmployeeInMyRange: () => axios.get({url: "/basedata/common/queryAllEmployeeInMyRange"}),
  // 获取当前登录人员的二维码
  getMyQrCode: () => axios.get({url: "/basedata/common/getMyQrCode"}),
  // 获取登录人员的门店信息
  getMyStore: () => axios.get({url: "/basedata/common/getMyStore"}),
  // 查询所有的职员
  queryAllEmployees: () => axios.get({url: "/basedata/common/queryAllEmployees"}),
  // 查询全部角色
  queryAllRoles: () => axios.get({url: "/basedata/common/queryAllRoles"}),
  // 查询客户列表
  queryStoreCustomers: (searchInfo) => axios.get({url: "/basedata/common/queryStoreCustomers", data: searchInfo}),
  // 查询某个门店的职员
  queryAllEmployeeByStore: (storeId) => axios.get({url: "/basedata/common/queryAllEmployeeByStore", data: {storeId}}),
  // 查询某个门店的职员
  downVideo: (params) => axios.getDown({url: "/basedata/common/downVideo", data: params}),
  // 查询全部职员用于显示，不用于其它地方
  queryAllEmployee4Show: () => axios.get({url: "/basedata/common/queryAllEmployee4Show"}),
  // 查询全部门店，只用于显示，不用于其它地方
  queryAllStore4Show: () => axios.get({url: "/basedata/common/queryAllStore4Show"}),
  // 查询全部调查表单
  queryAllQuestionList: (data) => axios.get({url: "/business/common/queryAllQuestionList", data: data}),
  // 查询客户信息，用于回显
  findCustomerInfo: (id) => axios.get({url: "/basedata/common/findCustomerInfo", data: {id}}),
  // 查询客户列表，用于自动补全
  queryCustomerList: (searchKey) => axios.get({url: "/basedata/common/queryCustomerList", data: {searchKey}}),
  // 查询客户列表，用于自动补全
  queryDictionaryList: (dicType) => axios.get({url: "/basedata/common/queryDictionaryList", data: {dicType}}),
  // 查询客户列表，用于自动补全
  queryDictionaryTree: (dicType) => axios.get({url: "/basedata/common/queryDictionaryTree", data: {dicType}}),




}
