<template>
  <!-- -->
  <div :class="`table-list table-list${itemCount}`">
    <slot/>
  </div>
</template>

<script>

export default {
  props: {
    itemCount:{default: 1},
  },
  name: "",
  components: {
  },
  data() {
    return {
      tableHeight: 500,
      interval: "",
      // 获取上级的高度
    };
  },
  created() {
  },
  destroyed() {
    if (this.interval) {
      clearTimeout(this.interval)
    }
  },
  mounted() {
    this.initHeight();
  },
  methods: {
    initHeight() {
      if (this.$parent.setMainHeightObserver) {
        this.$parent.setMainHeightObserver(this.changeHeight);
      }
    },
    changeHeight(height) {
      this.tableHeight = height;
    }
  }
};
</script>

<style lang="less" scoped>
.table-list{
  box-sizing: border-box;
  overflow: auto;
  height: calc(100% - var(--tabPanelHeight));
}

:deep(.el-table--border th.el-table__cell){
  background: #f5f7fa;
  color: #333;
  font-size: 14px;
  text-align: center;
}

:deep(.el-descriptions__label) {
  width: 10%;
}
:deep(.el-descriptions__label:after) {
}
:deep(.el-descriptions__content) {
  width: 23%;
}
:deep(.el-descriptions__content:first-child) {
  width: 24%;
}
:deep(.el-textarea__inner) {
  box-sizing: border-box;
  padding: 10px;
}
</style>