import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login'
import ChangePassword from '../views/ChangePassword'
import ForgetPassword from '../views/ForgetPassword'
import Home from '../views/Home'
import Welcome from '../views/Welcome'

// 首页配置
const SetHome = () =>
    import( /* webpackChunkName: "SetHome" */ '../views/applet/SetHome.vue')
// 基础信息
const Basic = () =>
    import( /* webpackChunkName: "Basic" */ '../views/applet/Basic.vue')
// 评价模板
const Evaluate = () =>
    import( /* webpackChunkName: "Evaluate" */ '../views/applet/Evaluate.vue')
// 使用指南
const Manual = () =>
    import( /* webpackChunkName: "Manual" */ '../views/applet/Manual.vue')
const ManualDetail = () =>
    import( /* webpackChunkName: "ManualDetail" */ '../views/applet/ManualDetail.vue')
// 小程序客服
const Service = () =>
    import( /* webpackChunkName: "Service" */ '../views/applet/Service.vue')

// 合同列表
const Contracts = () =>
    import( /* webpackChunkName: "Contracts" */ '../views/contract/Contracts.vue')
const ContractsDetail = () =>
    import( /* webpackChunkName: "ContractsDetail" */ '../views/contract/ContractsDetail.vue')
const Order = () =>
    import( /* webpackChunkName: "Order" */ '../views/contract/Order.vue')
// 安装施工
const Installation = () =>
    import( /* webpackChunkName: "Installation" */ '../views/contract/Installation.vue')
// 售后服务
const Aftersale = () =>
    import( /* webpackChunkName: "Aftersale" */ '../views/contract/Aftersale.vue')
const AftersaleDetail = () =>
    import( /* webpackChunkName: "AftersaleDetail" */ '../views/contract/AftersaleDetail.vue')

// 客户案例
const Case = () =>
    import( /* webpackChunkName: "Case" */ '../views/marketing/Case.vue')
const CaseDetail = () =>
    import( /* webpackChunkName: "CaseDetail" */ '../views/marketing/CaseDetail.vue')
// 文章资讯
const Actciles = () =>
    import( /* webpackChunkName: "Actciles" */ '../views/marketing/Actciles.vue')
const ActcilesDetail = () =>
    import( /* webpackChunkName: "ActcilesDetail" */ '../views/marketing/ActcilesDetail.vue')
// 营销视频
const MarketingVideo = () =>
    import( /* webpackChunkName: "MarketingVideo" */ '../views/marketing/MarketingVideo.vue')
// 话术管理
const VerbalTrick = () =>
    import( /* webpackChunkName: "VerbalTrick" */ '../views/marketing/trick/VerbalTrick.vue')
const VerbalDetail = () =>
    import( /* webpackChunkName: "VerbalDetail" */ '../views/marketing/trick/VerbalDetail.vue')
// 海报模板
const Poster = () =>
    import( /* webpackChunkName: "Poster" */ '../views/marketing/Poster.vue')
// 海报模板详情
const PosterDetail = () =>
    import( /* webpackChunkName: "Poster" */ '../views/marketing/PosterDetail.vue')
// 宣传海报
const Propaganda = () =>
    import( /* webpackChunkName: "Propaganda" */ '../views/marketing/Propaganda.vue')
    // 宣传海报详情
const PropagandaDetail = () =>
import( /* webpackChunkName: "Propaganda" */ '../views/marketing/PropagandaDetail.vue')
// 工作量统计
const Workload = () =>
    import( /* webpackChunkName: "Workload" */ '../views/marketing/Workload.vue')
// 转化率统计
const ConversionRate = () =>
    import( /* webpackChunkName: "ConversionRate" */ '../views/marketing/ConversionRate.vue')
// 引流活码
const LiveCode = () =>
    import( /* webpackChunkName: "LiveCode" */ '../views/marketing/LiveCode.vue')
// 欢迎语
const Greeting = () =>
    import( /* webpackChunkName: "Greeting" */ '../views/marketing/Greeting.vue')

// 客户列表
const Clients = () =>
    import( /* webpackChunkName: "Clients" */ '../views/user/Clients.vue')
const ClientsDetail = () =>
    import( /* webpackChunkName: "ClientsDetail" */ '../views/user/ClientsDetail.vue')
// 客户公海
const Opensea = () =>
    import( /* webpackChunkName: "Opensea" */ '../views/user/Opensea.vue')
// 跟进记录
const Follow = () =>
    import( /* webpackChunkName: "Follow" */ '../views/user/Follow.vue')
// 会话存档
const Session = () =>
    import( /* webpackChunkName: "Session" */ '../views/user/Session.vue')
// 客户群管理
const ClientsGroup = () =>
    import( /* webpackChunkName: "ClientsGroup" */ '../views/user/chatGroup/ClientsGroup.vue')
// 自动化群管理
const AutomationGroup = () =>
    import( /* webpackChunkName: "AutomationGroup" */ '../views/user/AutomationGroup.vue')
// 客户画像标签
const Tag = () =>
    import( /* webpackChunkName: "Tag" */ '../views/user/Tag.vue')
// 企微朋友圈
const Moments = () =>
    import( /* webpackChunkName: "Moments" */ '../views/user/Moments.vue')
const MomentsDetail = () =>
    import( /* webpackChunkName: "MomentsDetail" */ '../views/user/MomentsDetail.vue')

// 门店管理
const Store = () =>
    import( /* webpackChunkName: "Store" */ '../views/team/Store.vue')
const StoreDetail = () =>
    import( /* webpackChunkName: "StoreDetail" */ '../views/team/StoreDetail.vue')
// 渠道管理
const Channel = () =>
    import( /* webpackChunkName: "Channel" */ '../views/team/Channel.vue')
// 培训课程
const Courses = () =>
    import( /* webpackChunkName: "Courses" */ '../views/team/Courses.vue')
const CoursesDetail = () =>
    import( /* webpackChunkName: "CoursesDetail" */ '../views/team/CoursesDetail.vue')

const CoursesTest = () =>
    import( /* webpackChunkName: "CoursesTest" */ '../views/team/CoursesTest.vue')
// 课程计划
const Plan = () =>
    import( /* webpackChunkName: "Plan" */ '../views/team/Plan.vue')
const PlanDetail = () =>
    import( /* webpackChunkName: "PlanDetail" */ '../views/team/PlanDetail.vue')
// 日程活动
const Itinerary = () =>
    import( /* webpackChunkName: "Itinerary" */ '../views/team/Itinerary.vue')

// 客户投诉
const Complaint = () =>
    import( /* webpackChunkName: "Complaint" */ '../views/risk/Complaint.vue')
const ComplaintDetail = () =>
    import( /* webpackChunkName: "ComplaintDetail" */ '../views/risk/ComplaintDetail.vue')
// 催单
const Reminder = () =>
    import( /* webpackChunkName: "Reminder" */ '../views/risk/Reminder.vue')
const ReminderDetail = () =>
    import( /* webpackChunkName: "ReminderDetail" */ '../views/risk/ReminderDetail.vue')
// 敏感词管理
const SensitiveWord = () =>
    import( /* webpackChunkName: "SensitiveWord" */ '../views/risk/SensitiveWord.vue')
// 敏感词违规
const Violation = () =>
    import( /* webpackChunkName: "Violation" */ '../views/risk/Violation.vue')
// 敏感词统计
const WordStatistics = () =>
    import( /* webpackChunkName: "WordStatistics" */ '../views/risk/WordStatistics.vue')
// 客户联系风险
const Risk = () =>
    import( /* webpackChunkName: "Risk" */ '../views/risk/Risk.vue')

// 营销活动
const Marketing = () =>
    import( /* webpackChunkName: "Marketing" */ '../views/claim/Marketing.vue')
const MarketingDetail = () =>
    import( /* webpackChunkName: "MarketingDetail" */ '../views/claim/MarketingDetail.vue')
const MarketingTask = () =>
    import( /* webpackChunkName: "MarketingTask" */ '../views/claim/MarketingTask.vue')

// 销售排行
const Ranking = () =>
    import( /* webpackChunkName: "Ranking" */ '../views/claim/Ranking.vue')
// 裂变活动
const FissionActivities = () =>
    import( /* webpackChunkName: "FissionActivities" */ '../views/claim/FissionActivities.vue')
const FissionDetail = () =>
    import( /* webpackChunkName: "FissionDetail" */ '../views/claim/FissionDetail.vue')
// 裂变任务
const FissionTask = () =>
    import( /* webpackChunkName: "FissionTask" */ '../views/claim/FissionTask.vue')
const taskDetail = () =>
    import( /* webpackChunkName: "taskDetail" */ '../views/claim/taskDetail.vue')
// 客户SOP任务
const Sop = () =>
    import( /* webpackChunkName: "Sop" */ '../views/claim/Sop.vue')

// 数据字典
const Dictionary = () =>
    import( /* webpackChunkName: "Dictionary" */ '../views/manage/Dictionary.vue')
// 员工管理
const Staff = () =>
    import( /* webpackChunkName: "Staff" */ '../views/manage/usermanage/Staff.vue')
// 商品管理
const Goods = () =>
    import( /* webpackChunkName: "Goods" */ '../views/manage/Goods.vue')
const GoodsDetail = () =>
    import( /* webpackChunkName: "GoodsDetail" */ '../views/manage/GoodsDetail.vue')
// 客户SOP规则
const Soprules = () =>
    import( /* webpackChunkName: "Soprules" */ '../views/manage/Soprules.vue')
const SopDetail = () =>
    import( /* webpackChunkName: "SopDetail" */ '../views/manage/SopDetail.vue')
// 客户群SOP规则
const GropSoprules = () =>
    import( /* webpackChunkName: "GropSoprules" */ '../views/manage/GropSoprules.vue')
// ERP商品管理
const ERP = () =>
    import( /* webpackChunkName: "ERP" */ '../views/manage/ERP.vue')
const ERPDetail = () =>
    import( /* webpackChunkName: "ERPDetail" */ '../views/manage/ERPDetail.vue')
// 角色管理
const Role = () =>
    import( /* webpackChunkName: "Role" */ '../views/manage/Role2.vue')
// 系统配置
const SystemSetting = () =>
    import( /* webpackChunkName: "SystemSetting" */ '../views/manage/SystemSetting.vue')
// 好评库
const GoodComment = () =>
    import( /* webpackChunkName: "GoodComment" */ '../views/manage/GoodComment.vue')
// 手册
const Handbook = () =>
    import( /* webpackChunkName: "Handbook" */ '../views/manage/Handbook.vue')
// 操作日志
const ULog = () =>
    import( /* webpackChunkName: "ULog" */ '../views/manage/ULog.vue')
// 销售概览
const marketForm = () =>
    import( /* webpackChunkName: "marketForm" */ '../views/statement/marketForm.vue')
// 跟进汇总
const collectForm = () =>
    import( /* webpackChunkName: "collectForm" */ '../views/statement/collectForm.vue')
// 转化漏斗
const transformForm = () =>
    import( /* webpackChunkName: "transformForm" */ '../views/statement/transformForm.vue')
// 员工分析
const staffForm = () =>
    import( /* webpackChunkName: "staffForm" */ '../views/statement/staffForm.vue')
// 内容营销
const contentForm = () =>
    import( /* webpackChunkName: "contentForm" */ '../views/statement/contentForm.vue')
// 抽奖活动
const lottery = () =>
    import( /* webpackChunkName: "lottery" */ '../views/activity/lottery.vue')
// 抽奖活动详情
const lotteryDetail = () =>
    import( /* webpackChunkName: "lotteryDetail" */ '../views/activity/lotteryDetail.vue')
// 中奖记录
const record = () =>
    import( /* webpackChunkName: "record" */ '../views/activity/record.vue')
// 抽奖分析
const analyse = () =>
    import( /* webpackChunkName: "analyse" */ '../views/activity/analyse.vue')

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/welcome'
}, {
    path: '/login',
    name: 'Login',
    component: Login
}, {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword
}, {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword
}, {
    path: '/handbook',
    name: 'Handbook',
    component: Handbook
},

{
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [{
        path: '/welcome',
        component: Welcome
    }, {
        path: '/set-home',
        name: 'SetHome',
        component: SetHome
    }, {
        path: '/basic',
        name: 'Basic',
        component: Basic
    }, {
        path: '/evaluate',
        name: 'Evaluate',
        component: Evaluate
    }, {
        path: '/manual',
        name: 'Manual',
        component: Manual
    }, {
        path: '/manual-detail',
        name: 'ManualDetail',
        component: ManualDetail
    }, {
        path: '/service',
        name: 'Service',
        component: Service
    }, {
        path: '/contracts',
        name: 'Contracts',
        component: Contracts
    }, {
        path: '/contracts-detail',
        name: 'ContractsDetail',
        component: ContractsDetail
    }, {
        path: '/order',
        name: 'Order',
        component: Order
    }, {
        path: '/installation',
        name: 'Installation',
        component: Installation
    }, {
        path: '/aftersale',
        name: 'Aftersale',
        component: Aftersale
    }, {
        path: '/aftersale-detail',
        name: 'AftersaleDetail',
        component: AftersaleDetail
    }, {
        path: '/case',
        name: 'Case',
        component: Case
    }, {
        path: '/case-detail',
        name: 'CaseDetail',
        component: CaseDetail
    }, {
        path: '/actciles',
        name: 'Actciles',
        component: Actciles
    }, {
        path: '/actciles-detail',
        name: 'ActcilesDetail',
        component: ActcilesDetail
    }, {
        path: '/marketing-video',
        name: 'MarketingVideo',
        component: MarketingVideo
    }, {
        path: '/verbal-trick',
        name: 'VerbalTrick',
        component: VerbalTrick
    }, {
        path: '/verbal-detail',
        name: 'VerbalDetail',
        component: VerbalDetail
    }, {
        path: '/clients',
        name: 'Clients',
        component: Clients
    }, {
        path: '/clients-detail',
        name: 'ClientsDetail',
        component: ClientsDetail
    }, {
        path: '/tag',
        name: 'Tag',
        component: Tag
    }, {
        path: '/moments',
        name: 'Moments',
        component: Moments
    }, {
        path: '/moments-detail',
        name: 'MomentsDetail',
        component: MomentsDetail
    }, {
        path: '/open-sea',
        name: 'Opensea',
        component: Opensea
    }, {
        path: '/follow',
        name: 'Follow',
        component: Follow
    }, {
        path: '/store',
        name: 'Store',
        component: Store
    }, {
        path: '/store-detail',
        name: 'StoreDetail',
        component: StoreDetail
    }, {
        path: '/channel',
        name: 'Channel',
        component: Channel
    }, {
        path: '/complaint',
        name: 'Complaint',
        component: Complaint
    }, {
        path: '/complaint-detail',
        name: 'ComplaintDetail',
        component: ComplaintDetail
    }, {
        path: '/reminder',
        name: 'Reminder',
        component: Reminder
    }, {
        path: '/reminder-detail',
        name: 'ReminderDetail',
        component: ReminderDetail
    }, {
        path: '/marketing-campaign',
        name: 'Marketing',
        component: Marketing
    }, {
        path: '/marketing-detail',
        name: 'MarketingDetail',
        component: MarketingDetail
    }, {
        path: '/marketing-task',
        name: 'MarketingTask',
        component: MarketingTask
    }, {
        path: '/ranking',
        name: 'Ranking',
        component: Ranking
    }, {
        path: '/sop',
        name: 'Sop',
        component: Sop
    }, {
        path: '/dictionary',
        name: 'Dictionary',
        component: Dictionary
    }, {
        path: '/staff',
        name: 'Staff',
        component: Staff
    }, {
        path: '/goods',
        name: 'Goods',
        component: Goods
    }, {
        path: '/goods-detail',
        name: 'GoodsDetail',
        component: GoodsDetail
    }, {
        path: '/sop-rules',
        name: 'Soprules',
        component: Soprules
    }, {
        path: '/sop-detail',
        name: 'SopDetail',
        component: SopDetail
    }, {
        path: '/grop-soprules',
        name: 'GropSoprules',
        component: GropSoprules
    }, {
        path: '/goods-erp',
        name: 'ERP',
        component: ERP
    }, {
        path: '/erp-detail',
        name: 'ERPDetail',
        component: ERPDetail
    }, {
        path: '/role',
        name: 'Role',
        component: Role
    }, {
        path: '/system-setting',
        name: 'SystemSetting',
        component: SystemSetting
    }, {
        path: '/good-comment',
        name: 'GoodComment',
        component: GoodComment
    }, {
        path: '/operator-log',
        name: 'ULog',
        component: ULog
    }, {
        path: '/sensitive-word',
        name: 'SensitiveWord',
        component: SensitiveWord
    }, {
        path: '/violation',
        name: 'Violation',
        component: Violation
    }, {
        path: '/word-statistics',
        name: 'WordStatistics',
        component: WordStatistics
    }, {
        path: '/risk',
        name: 'Risk',
        component: Risk
    }, {
        path: '/poster',
        name: 'Poster',
        component: Poster
    // }, {
    //     path: '/poster-detail',
    //     name: 'PosterDetail',
    //     component: PosterDetail
    },{path: '/poster-detail',name: '海报模板',component: () => import("../views/poster/temp/PosterDetail.vue")},
        {
        path: '/propaganda',
        name: 'Propaganda',
        component: Propaganda
    },{
        path: '/propaganda-detail',
        name: 'PropagandaDetail',
        component: PropagandaDetail
    }, {
        path: '/workload',
        name: 'Workload',
        component: Workload
    }, {
        path: '/conversion-rate',
        name: 'ConversionRate',
        component: ConversionRate
    }, {
        path: '/live-code',
        name: 'LiveCode',
        component: LiveCode
    }, {
        path: '/greeting',
        name: 'Greeting',
        component: Greeting
    }, {
        path: '/session',
        name: 'Session',
        component: Session
    }, {
        path: '/clients-group',
        name: 'ClientsGroup',
        component: ClientsGroup
    }, {
        path: '/automation-group',
        name: 'AutomationGroup',
        component: AutomationGroup
    }, {
        path: '/courses',
        name: 'Courses',
        component: Courses
    }, {
        path: '/courses-detail',
        name: 'CoursesDetail',
        component: CoursesDetail
    }, {
        path: '/courses-test',
        name: 'CoursesTest',
        component: CoursesTest
    }, {
        path: '/plan',
        name: 'Plan',
        component: Plan
    }, {
        path: '/plan-detail',
        name: 'PlanDetail',
        component: PlanDetail
    }, {
        path: '/itinerary',
        name: 'Itinerary',
        component: Itinerary
    }, {
        path: '/fission-activities',
        name: 'FissionActivities',
        component: FissionActivities
    }, {
        path: '/fission-detail',
        name: 'FissionDetail',
        component: FissionDetail
    }, {
        path: '/fission-task',
        name: 'FissionTask',
        component: FissionTask
    }, {
        path: '/task-detail',
        name: 'taskDetail',
        component: taskDetail
    }, {
        path: '/market-form',
        name: 'marketForm',
        component: marketForm
    }, {
        path: '/collect-form',
        name: 'collectForm',
        component: collectForm
    }, {
        path: '/transform-form',
        name: 'transformForm',
        component: transformForm
    }, {
        path: '/staff-form',
        name: 'staffForm',
        component: staffForm
    }, {
        path: '/content-form',
        name: 'contentForm',
        component: contentForm
    }, {
        path: '/lottery',
        name: 'lottery',
        component: lottery
    }, {
        path: '/lottery-detail',
        name: 'lotteryDetail',
        component: lotteryDetail
    }, {
        path: '/winning',
        name: 'record',
        component: record
    }, {
        path: '/analyse',
        name: 'analyse',
        component: analyse
    },
      {path: '/charts/saleview', name: 'chartsSaleView', component: () => import('../views/charts/saleview/SaleView')},
      {path: '/charts/followview', name: 'chartsFollowView', component: () => import('../views/charts/followview/FollowView')},
      {path: '/searchTest',name: 'searchTest',component: () => import("../views/test/TestSearch")},
      // 课程-新
      {path: '/college/list',name: '商学院',component: () => import("../views/college/College")},
      {path: '/college/detail',name: '商学院详情',component: () => import("../views/college/LessonDetail")},
      {path: '/college/question',name: '试题库',component: () => import("../views/college/Question")},
      {path: '/college/teacher',name: '讲师',component: () => import("../views/college/Components/CollegeTeacherDetail")},
      // 区域管理
      {path: '/sys/area',name: '区域管理',component: () => import("../views/sys/AreaManage")},
      // 二维码
      {path: '/sys/qrcode',name: '二维码',component: () => import("../views/sys/qrcode/QrCode")},
      // 新版营销活动
      {path: '/activity/sale-activity',name: '营销活动',component: () => import("../views/activity/saleActivity/saleActivity")},
      {path: '/activity/activity-detail',name: '营销活动详情',component: () => import("../views/activity/saleActivity/saleActivityDetail")},
      {path: '/activity/activity-rank',name: '营销活动排行',component: () => import("../views/activity/saleActivity/saleActivityRank")},
      {path: '/test/map',name: '营销活动详情',component: () => import("../views/team/Map")},
      // 新版抽奖
      {path: '/lottery/lottery-detail2',name: '营销活动排行',component: () => import("../views/lottery/LotteryDetail")},
      // 新版合同详情
      {path: '/contracts/list',name: '合同详情',component: () => import("../views/contract/ContractListStore")},
      // 新版系统配置
      {path: '/system/setting',name: '合同详情',component: () => import("../views/manage/systemsetting/SystemSetting")},
      // 调查表单
      {path: '/sale/question',name: '调查表单列表',component: () => import("../views/question/QuestionList")},
      {path: '/sale/question/detail',name: '调查表单详情',component: () => import("../views/question/QuestionDetail")},
      {path: '/college/class/package',name: '编辑课程包',component: () => import("../views/college/Components/ClassPackageDetail")},
        {path: '/test/imageDrage',name: '编辑课程包',component: () => import("../views/test/TestImage")}, // 测试用
        {path: '/contract/quotation',name: '报价方案',component: () => import("../views/contract/quotation/QuotationList.vue")},
        {path: '/contract/quotation/detail',name: '报价方案',component: () => import("../views/contract/quotation/QuotationDetail.vue")},
    ]
}
]

const router = new VueRouter({
    routes
})

// 挂载路由导航守卫
router.beforeEach((to, form, next) => {
    if (to.path === '/login' || to.path === '/forget-password') {
        return next()
    }
    const tokenStr = window.localStorage.getItem('token')
    if (!tokenStr) {
        return next('/login')
    } else {
        next()
    }
})

export default router
