<template>
  <!-- 统计功能通用查询条件 -->
  <div style="position: relative;" class="search-condition" :id="id">
    <range-date-picker class="condition condition-twice" v-if="showTime" ref="customerDate" @changeDate="changeDate" :start-time.sync="searchInfo.startTime" :end-time.sync="searchInfo.endTime"/>
    <!-- 超级管理员或店长显示区域 -->
    <el-select filterable clearable class="condition" size="small" placeholder="请选择区域" v-show="showArea" v-if="userData.roleType === 0 || userData.leader === 1" :value="searchInfo.areaId" v-model="searchInfo.areaId" @change="areaChanged">
      <el-option v-for="(item, index) in areas" :value="item.id" :key="index" :label="item.areaName"/>
    </el-select>
    <el-select filterable clearable class="condition" size="small" placeholder="请选择门店" v-if="showStore && (userData.roleType === 0 || userData.leader === 1)" :value="searchInfo.storeId" v-model="searchInfo.storeId" @change="storeChanged">
      <el-option v-for="(item, index) in stores" :value="item.id" :key="index" :label="item.storeName"/>
    </el-select>
    <el-select filterable clearable class="condition" size="small" placeholder="请选择职员" v-if="showEmployee" :value="searchInfo.employeeId" v-model="searchInfo.employeeId" @change="employeeChanged">
      <el-option v-for="(item, index) in employees" :value="item.id" :key="index" :label="item.userName"/>
    </el-select>
    <el-select filterable clearable class="condition" multiple size="small" placeholder="请选择职员" v-if="showMultiEmployees" :value="searchInfo.employeeIdList" v-model="searchInfo.employeeIdList" @change="employeeListChanged">
      <el-option v-for="(item, index) in employees" :value="item.id" :key="index" :label="item.userName"/>
    </el-select>
    <slot></slot>
  </div>
</template>

<script>
import RangeDatePicker from "@/components/RangeDateTimePicker";
import util from "@/util/util";

export default {
  emits: ["dataChange", "ready"],
  components: {
    RangeDatePicker
  },
  props: {
    // 查询条件
    searchInfo: {default: () => {}},
    showTime: {default: false},
    showArea: {default: true},
    showStore: {default: true},
    showEmployee: {default: false},
    // 多选职员
    showMultiEmployees: {default: false},
  },
  watch: {
    // 监控数据变更
    'searchInfo.areaId': {
      handler() {
        // 数据发生变化，
        this.showStores();
        this.showEmployees();
      },
      deep: true,
    },
    'searchInfo.storeId': {
      handler() {
        // 数据发生变化，
        this.showEmployees();
      },
      deep: true,
    },
    'searchInfo.employeeId': {
      handler() {
        // 数据发生变化，
      },
    },
  },
  data() {
    return {
      id: "",
      allAreas: [],
      allStores: [],
      allEmployees: [],
      areas: [],
      stores: [],
      employees: [],
      userData: {}
    };
  },
  created() {
    this.id = "search_condition2" + (Math.random()*9999).toFixed(0);
    this.userData = JSON.parse(localStorage.getItem("userData"));

  },
  mounted() {
    this.init();
    this.$nextTick().then(() => this.resetParentHeight());
  },
  methods: {
    resetParentHeight() {
      if (this.$parent.addConditionHeight) {
        let docHeight = document.querySelector(`#${this.id}`).clientHeight;
        let res = this.$parent.addConditionHeight(docHeight);
        if (!res) {
          // 设置失败，等会再试
          this.timeout = setTimeout(() => this.resetParentHeight(), 300);
        }
      }
    },
    // 数据显示
    async init() {
      let count = 0;
      // 查询所有的数据，缓存在前端
      // 区域
      util.getAreaList().then(data => {
        this.allAreas = data;
        this.areas = data;
        count++;
        if (count === 3) {
          this.$emit("ready");
        }
      });
      // 门店
      util.getStoreList().then(data => {
        this.allStores = data;
        this.showStores();
        // 如果是门店负责人
        let user = util.getUserInfo();
        if (user.roleType !== 0) {
          for (let i = 0; i < this.allStores.length; i++) {
            if (this.allStores[i].id === user.storeId) {
              let res = {
                ...this.searchInfo,
                areaId: this.allStores[i].areaId,
                storeId: this.allStores[i].id,
                employeeId: "",
                startTime: "",
                endTime: ""
              }
              this.$emit("update:searchInfo", res);
              this.dataChange()
              break;
            }
          }
        }
        count++;
        if (count === 3) {
          this.$emit("ready");
        }
      });
      // 职员
      util.getEmployeeList().then(data => {
        this.allEmployees = data;
        this.showEmployees();
        count++;
        if (count === 3) {
          this.$emit("ready");
        }
      })
    },
    showStores() {
      let stores = [];
      if (this.searchInfo.areaId) {
        // 显示门店和职员
        for (let i = 0; i < this.allStores.length; i++) {
          if (this.allStores[i].areaId === this.searchInfo.areaId) {
            stores.push(this.allStores[i]);
          }
        }
        this.stores = stores;
      } else {
        this.stores = this.allStores;
      }
    },
    showEmployees() {
      let employees= [];
      if (!this.allEmployees) {
        return;
      }
      for (let i = 0; i < this.allEmployees.length; i++) {
        // 是否符合条件
        let add = true;
        // areaId
        if (this.searchInfo.areaId) {
          // 限制区域
          add = this.searchInfo.areaId === this.allEmployees[i].areaId;
        }
        if (this.searchInfo.storeId) {
          add = this.searchInfo.storeId === this.allEmployees[i].storeId;
        }
        // 符合area和store的条件
        if (add) {
          this.pushEmployee(employees, this.allEmployees[i]);
        }
      }
      this.employees = employees;
    },
    // 去重添加，如果有重复的，就不添加到emList，如果没有重复的就添加进去
    pushEmployee(arr, em) {
      let contains = false;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === em.id) {
          contains = true;
          break;
        }
      }
      if (!contains) {
        arr.push(em);
      }
    },
    queryAreas() {
      // 查询所有的区域
      this.$http.get(this.URL.queryAreas, {}).then(({data}) => {
        this.areas = data.data;
      });
    },
    // queryStores() {
    //
    //   this.$http.get(this.URL.queryStores, {params: {areaId: this.searchInfo.areaId}}).then(({data}) => {
    //     this.stores = data.data;
    //   });
    // },
    // queryEmployees() {
    //   this.$http.get(this.URL.queryAllEmployeeByStore, {params: {storeId: this.searchInfo.storeId}}).then(({data}) => {
    //     this.employees = data.data;
    //   });
    // },
    dataChange() {
      this.$emit("dataChange");
    },
    // 时间变更
    changeDate(data) {
      let startTime = data? data[0]: null;
      let endTime = data? data[1]: null;
      let res = {
        ...this.searchInfo,
        areaId: this.searchInfo.areaId, storeId: this.searchInfo.storeId, employeeId: this.searchInfo.employeeId, startTime: startTime, endTime: endTime
      }
      this.$emit("update:searchInfo", res);
      this.dataChange();
    },
    areaChanged() {
      let data = {...this.searchInfo};
      data.storeId = null;
      data.employeeId = null;
      this.$emit("update:searchInfo", data);
      this.showStores();
      this.showEmployees()
      this.dataChange();
    },
    storeChanged() {
      let data = {...this.searchInfo};
      data.employeeId = null;
      this.$emit("update:searchInfo", data);
      this.showEmployees();
      this.dataChange();
    },
    employeeChanged() {
      this.$forceUpdate();
      this.dataChange();
    },
    employeeListChanged() {
      this.$forceUpdate();
      this.dataChange();
    }
  },
};
</script>

<style lang="less" scoped>
.search-condition{
  --condition-margin: 1%; // 外边距宽度
  --condition-width-1: 15%; // 单倍宽度
  --condition-width-2: 31%;
}
.el-select{
  margin-right: 10px;
  width: 240px;
}
/deep/.el-button{
  margin-right: 10px;
  vertical-align: baseline;
}
.search-condition:last-child{
  margin-right: 0;
}
/deep/.el-input{
  margin-bottom: 10px;
  width: var(--condition-width-1);
  margin-right: var(--condition-margin);
}
/deep/ .el-button{
  margin-bottom: 10px;
  margin-top: 0 !important;
}
/deep/ .el-select{
  margin-right: var(--condition-margin);
  margin-bottom: 10px;
  width: var(--condition-width-1);
  .el-input{
    width: 100%;
  }
}
.condition{
  margin-right: var(--condition-margin);
  margin-bottom: 10px;
  width: var(--condition-width-1);
}
.condition:nth-last-child{
  margin-right: 0;
}
/deep/ .el-select .el-input{
  margin-bottom: 0;
}
.condition-twice{
  width: var(--condition-width-2);
}
</style>
