<template>
  <div class="bg-box">
    <!-- <img src="../assets/images/bg-logo.png" alt="" /> -->
    <h1 class="title">金致HOME数字化赋能系统</h1>
    <div class="login">
      <h3>修改密码</h3>
      <h5>Change Password</h5>
      <el-form
        label-width="0px"
        class="login_form"
        :model="changeForm"
        :rules="changeFormRules"
        ref="changeFormRef"
      >
        <el-form-item prop="userAccount">
          <el-input
            prefix-icon="el-icon-mobile-phone"
            autocomplete="off"
            v-model="changeForm.userAccount"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="oldPassword">
          <el-input
            prefix-icon="el-icon-lock"
            v-model="changeForm.oldPassword"
            :type="passwordType"
            autocomplete="new-password"
            placeholder="请输入原密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            prefix-icon="el-icon-lock"
            v-model="changeForm.pass"
            :type="passwordType"
            autocomplete="new-password"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="userPassword">
          <el-input
            prefix-icon="el-icon-lock"
            v-model="changeForm.userPassword"
            :type="passwordType"
            autocomplete="new-password"
            placeholder="请再次输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="modification" class="btn"
            >修改密码</el-button
          >
          <!-- <el-button @click="changeFormRef">重置</el-button> -->
          <div class="forget" @click="$router.go(-1)">
            <i class="el-icon-d-arrow-left"></i>
            <span>返回</span>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <!-- <icall-call ref="myCall" style="display:none"></icall-call> -->
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.changeForm.userPassword !== "") {
          this.$refs.changeFormRef.validateField("userPassword");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.changeForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      passwordType: "password",
      // 表单绑定
      changeForm: {
        userAccount: "",
        oldPassword: "",
        pass: "",
        userPassword: "",
      },
      // 表单验证规则
      changeFormRules: {
        userAccount: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        pass: [{ validator: validatePass, trigger: "blur" }],
        userPassword: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  mounted() {},
  methods: {
    // 重置表单
    changeFormRef() {
      this.$refs.changeFormRef.resetFields();
    },
    // 登录
    modification() {
      // 表单预验证
      this.$refs.changeFormRef.validate(async (valid) => {
        // 返回一个布尔值，判断表单规则
        if (!valid) return;
        const form = { ...this.changeForm };
        const info = {
          userAccount: form.userAccount,
          oldPassword: form.oldPassword,
          userPassword: form.userPassword,
        };
        const { data: res } = await this.$http.post(
          this.URL.changePassword,
          info
        );
        if (res.code != 2000) return this.$message.error(res.message);
        this.$message.success(res.message);
        localStorage.clear();
        this.$router.push("/");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bg-box {
  background: url("../assets/images/bg.png") no-repeat;
  background-size: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  min-width: 1366px;
  height: 100%;
  background-color: #1780b8;
  img {
    width: 700px;
    position: absolute;
    top: 50%;
    right: 52%;
    transform: translateY(-50%);
  }
  .title {
    position: absolute;
    top: 5%;
    left: 50px;
    color: #fff;
  }
  .login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 580px;
    min-height: 530px;
    padding: 30px 50px 40px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 30px;
    h3 {
      text-align: center;
      font-size: 26px;
    }
    h5 {
      text-align: center;
      font-size: 24px;
      color: #999;
      margin-top: 0px;
      font-weight: normal;
      margin-bottom: 60px;
    }
    /deep/.el-input__icon {
      font-size: 20px;
    }
    .login_form {
      width: 80%;
      margin: 0 auto;
      .btn {
        width: 100%;
        // background-color: #b8171a;
        border: none;
        margin-top: 10px;
      }
    }
    /deep/.el-input--prefix .el-input__inner {
      padding-left: 40px;
    }
    .forget {
      cursor: pointer;
      text-align: right;
      margin-top: 10px;
      i {
        font-size: 18px;
        margin-right: 5px;
      }
    }
  }
}
</style>