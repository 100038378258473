export default {
    sessionKey: "pageParams",
    // 保存页面跳转的数据
    setParamsData(params) {
        let key = window.location.hash;
        let json = {key: params}
        sessionStorage.setItem(this.sessionKey, JSON.stringify(json));
    },
    // 获取页面跳转的数据
    getParamsData() {
        let json = sessionStorage.getItem(this.sessionKey);
        let key = window.location.hash;
        if (json) {
            return JSON.parse(json).key;
        } else {
            return null;
        }
    },
    clear() {
        sessionStorage.removeItem(this.sessionKey);
    }
}