const URL = {

    // 公用
    login: '/basedata/base/loginByPassword',
    logout: '/basedata/common/logout',
    menu: '/basedata/common/getMenu',
    commonAllEmployees: '/basedata/common/queryAllEmployees',
    storeAllEmployees: '/basedata/sys/store/queryAllEmployees',
    getCurrentAddress: '/basedata/common/getCurrentAddress',
    changePassword: '/basedata/common/updatePassword',
    switchMyStore: '/basedata/common/switchMyStore',
    sendCheckCode: '/basedata/base/sendCheckCode',
    resetPassword: '/basedata/base/resetPassword',
    orgList: '/basedata/common/queryAllDeparts', //查询组织机构
    getStore: '/basedata/common/queryStoresByDepart', // 根据id查门店
    queryStores: "/basedata/common/queryStores",
    queryMyMessages: '/basedata/common/queryMyMessages',//查询消息列表
    readMessage: '/basedata/common/readMessage',//查看某一条数据
    queryAreas: '/basedata/common/queryAreas',// 查询所有区域
    queryAllEmployeeInMyRange: "/basedata/common/queryAllEmployeeInMyRange", //查询所有职员
    queryAllEmployeeByStore: '/basedata/common/queryAllEmployeeByStore', //按门店查询所有职员


    // 文件上传
    // md5验证
    checkMD5: '/business/base/file/check',
    // 分片
    uploadByPiece: '/business/base/file/uploadByPiece',
    // 合并
    mergeFile: '/business/base/file/mergeFile',
    // 营销视频合并
    mergeVideo: '/business/sale/video/mergeFile',


    // ***** 合同管理 *****
    // 合同列表
    contractList: '/business/sale/contract/queryList',
    contractDetail: '/business/sale/contract/findById',
    saveContract: '/business/sale/contract/save',
    removeContract: '/business/sale/contract/delete',
    updateContract: '/business/sale/contract/update',
    dealerList: '/business/sale/contract/stores',
    contractClue: '/business/sale/contract/clueList',
    contractOrder: '/business/sale/contract/queryOrder',
    confirmContract: '/business/sale/contract/confirm',
    statusEnum: '/business/sale/contract/statusEnum',
    completeContract: '/business/sale/contract/complete',
    editContract: '/business/sale/contract/update',
    exportContractList: '/business/sale/contract/exportContractList',
    receiveStead: '/business/sale/contract/receiveStead',
    queryContractReport:'/business/sale/contract/queryContractReport',//合同报表
    queryMergeContract:'/business/sale/contract/queryMergeContract',//合同统计
    areaList:'/business/sale/contract/queryAreas',//区域列表
    exportMergeContract:'/business/sale/contract/exportMergeContract',//导出合同统计
    exportContractReport:'/business/sale/contract/exportContractReport',//导出合同报表


    // 安装施工
    constructList: '/business/sale/construct/queryList',
    constructContract: '/business/sale/construct/findByContractId',
    constructWorkers: '/business/sale/construct/workers',
    assignConstruct: '/business/sale/construct/assign',
    assignBatchConstruct: '/business/sale/construct/assignBatch',
    queryMergeConstruct: '/business/sale/construct/queryMergeConstruct',
    exportMergeConstruct: '/business/sale/construct/exportMergeConstruct',
    constructStores: '/business/sale/construct/stores',
    // 售后服务
    serviceList: '/business/sale/service/queryList',
    serviceDetail: '/business/sale/service/findById',
    saveService: '/business/sale/service/merge',
    serviceWorkers: '/business/sale/service/workers',
    assignService: '/business/sale/service/assign',
    serviceContract: '/business/sale/service/contracts',
    serviceGoods: '/business/sale/service/contract/products',
    queryMergeSaleService: '/business/sale/service/queryMergeSaleService',
    exportMergeSaleService: '/business/sale/service/exportMergeSaleService',

    // ***** 营销管理 *****
    // 海报模板
    savePoster: '/business/poster/template/save', // 保存
    posterUpload: '/business/poster/template/upload/image?requireDomain=1', // 图片上传
    posterList: '/business/poster/template/queryList', // 列表
    posterDetail: '/business/poster/template/findById', // 详情
    removePoster: '/business/poster/template/delete', // 删除
    editPoster: '/business/poster/template/update', // 修改
    posterAna: '/business/poster/template/analysis', // 数据解析
    getTemplateTypes: '/business/poster/template/getAllTypes', //
    // 宣传海报
    publicList: '/business/publicity/poster/queryList', // 列表
    savePublic: '/business/publicity/poster/save', //保存
    savePublicOther: '/business/publicity/poster/saveOther', //另存
    publicDetail: '/business/publicity/poster/findById', // 详情
    editPublic: '/business/publicity/poster/update', // 修改
    removePublic: '/business/publicity/poster/delete', // 删除
    publicUpload: '/business/publicity/poster/upload/image/', // 图片上传
    typeList: '/business/publicity/poster/typeList',
    getPosterTypes: '/business/publicity/poster/getAllTypes', //
    myCollections: '/business/common/myCollectionPosters',
    queryMyPosterList: '/business/common/queryMyPosterList',
    addCollection: '/business/publicity/poster/addCollection',
    deleteCollection: '/business/publicity/poster/deleteCollection',
    // 客户案例
    caseList: '/basedata/server/saleCase/querySaleCases',
    caseDetail: '/basedata/server/saleCase/findSaleCaseById',
    saveCase: '/basedata/server/saleCase/saveSaleCase',
    removeCase: '/basedata/server/saleCase/delete',
    caseUpload: '/basedata/server/saleCase/uploadFile?requireDomain=1',
    dictionaries: '/basedata/server/saleCase/allSaleCaseDictionaries',
    caseParent: '/basedata/server/saleCase/queryParentList',
    // 文章资讯
    articleList: '/business/article/info/queryList',
    articleChild: '/business/article/info/childList',
    articleFolders: '/business/article/info/queryFolders',
    saveArticle: '/business/article/info/save',
    removeArticle: '/business/article/info/delete',
    articleDetail: '/business/article/info/findById',
    editArticle: '/business/article/info/update',
    articleType: '/business/article/info/docTypes',
    articleUpload: '/business/article/info/upload?requireDomain=1',
    // 营销视频
    videoList: '/business/sale/video/queryList',
    videoChild: '/business/sale/video/childList',
    videoFolders: '/business/sale/video/queryFolders',
    saveVideo: '/business/sale/video/save',
    removeVideo: '/business/sale/video/delete',
    videoDetail: '/business/sale/video/findById',
    editVideo: '/business/sale/video/update',
    uploadVideo: '/business/sale/video/upload/video?requireDomain=1',
    // 话术管理
    craftList: '/business/speech/craft/queryList',
    saveCraft: '/business/speech/craft/save',
    removeCraft: '/business/speech/craft/delete',
    craftDetail: '/business/speech/craft/findById',
    editCraft: '/business/speech/craft/update',
    allCraft: '/business/speech/craft/queryAll',
    craftScope: '/business/speech/craft/storeList',
    craftChild: '/business/speech/craft/childList',

    // ***** 客户和客户群 *****
    // 画像标签
    tagList: '/basedata/customer/tag/queryList',
    removeTag: '/basedata/customer/tag/delete',
    // 客户列表
    clueList: '/basedata/customer/clue/queryList',
    clueTags: '/basedata/customer/clue/queryAllTags',
    clueCategories: '/basedata/customer/clue/queryCategories',
    saveClue: '/basedata/customer/clue/saveClueAndTags',
    saveNewClue: '/basedata/customer/clue/saveNew',
    saveSea: '/basedata/customer/clue/recoveryCustomer',
    removeClue: '/basedata/customer/clue/delete',
    clueDetail: '/basedata/customer/clue/findById',
    recordList: '/basedata/customer/clue/queryFollowRecords',
    recordDetail: '/basedata/customer/clue/findFollowRecord',
    clueAction: '/basedata/customer/clue/queryClueAction',
    clueStores: '/basedata/customer/clue/queryAllStores',
    InvalidEmployees: '/basedata/customer/clue/queryInvalidEmployees',
    transferList: '/basedata/customer/clue/queryAllEmployee',
    customerTranslate: '/basedata/customer/clue/customerTranslate',
    queryMergeClues: '/basedata/customer/clue/queryMergeClues',
    exportMergeClues: '/basedata/customer/clue/exportMergeClues',
    // 客户公海
    seaList: '/basedata/customer/clueSea/queryList',
    assignClue: '/basedata/customer/clueSea/assignClue',
    assignClueList: 'basedata/customer/clueSea/assignClueList',
    seaCategories: '/basedata/customer/clueSea/queryCategories',
    seaDetail: '/basedata/customer/clueSea/findById',
    seaRecords: '/basedata/customer/clueSea/queryFollowRecords',
    seaRecordsDetail: '/basedata/customer/clueSea/findFollowRecord',
    seaAction: '/basedata/customer/clueSea/queryClueAction',
    downloadClueTemplate: '/basedata/customer/clueSea/downloadClueTemplate',
    uploadClues: '/basedata/customer/clueSea/uploadClues',
    queryMergeClueSea: '/basedata/customer/clueSea/queryMergeClueSea',
    exportMergeClueSea: '/basedata/customer/clueSea/exportMergeClueSea',
    // 跟进记录
    followList: '/basedata/customer/record/queryFollowRecords',
    followDetail: '/basedata/customer/record/findById',
    deleteRecord: '/basedata/customer/record/delete',
    followCategories: '/basedata/customer/record/queryCategories',
    resourcesTransfer: '/basedata/sys/store/resourcesTransfer',
    followUser: '/basedata/customer/clue/queryFollowersByClue',
    queryMergeClueFollowRecords: '/basedata/customer/record/queryMergeClueFollowRecords',
    exportMergeClueFollowRecords: '/basedata/customer/record/exportMergeClueFollowRecords',
    // 客户群管理
    customerGroupList: '/basedata/customer/group/queryList',
    customerGroupMembers: '/basedata/customer/group/queryMembers',
    // 朋友圈
    circleList: '/basedata/customer/circle/queryList',
    circleDetail: '/basedata/customer/circle/findById',
    saveCircle: '/basedata/customer/circle/save',
    removeCircle: '/basedata/customer/circle/delete',
    circleUpload: '/basedata/customer/circle/uploadImage?requireDomain=1',
    circleCases: '/basedata/customer/circle/querySaleCases',
    circleProducts: '/basedata/customer/circle/queryProducts',
    circleDeparts: '/basedata/customer/circle/queryAllDeparts',

    // ***** 团队管理 *****
    // 门店管理
    storeList: '/basedata/sys/store/queryList',
    saveStore: '/basedata/sys/store/saveStore',
    storeDetail: '/basedata/sys/store/findById',
    removeStore: '/basedata/sys/store/delete',
    freezeStore: '/basedata/sys/store/freeze',
    unfreezeStore: '/basedata/sys/store/unfreeze',
    storeShortCode: '/basedata/sys/store/getShortCode',
    storeUpload: '/basedata/sys/store/uploadFile?requireDomain=1',
    storeQrCode: '/basedata/sys/store/getQrCode',
    storeSeaQrCode: '/basedata/sys/store/getQrCodeStore',
    storeDepartments: '/basedata/sys/store/queryDepartments',
    // 加盟
    storeApply: '/basedata/sys/store/storeApply',
    storeForm: '/basedata/sys/store/storeForm',
    acceptApply: '/basedata/sys/store/acceptApply',
    refuseApply: '/basedata/sys/store/refuseApply',
    // 渠道管理
    channelList: '/basedata/sys/channel/queryList',
    saveChannel: '/basedata/sys/channel/save',
    channelDetail: '/basedata/sys/channel/findById',
    removeChannel: '/basedata/sys/channel/delete',
    allEmployees: '/basedata/sys/channel/queryAllEmployees',
    shortCode: '/basedata/sys/channel/getShortCode',
    channelQrCode: '/basedata/sys/channel/getQrCode',
    channelDepartments: '/basedata/sys/channel/queryDepartments',
  /**
   * 商学院
   */
  classList: '/basedata/study/lesson/queryList',
  // 培训课程
    curriculumList: '/business/doc/curriculum/queryList',
    curriculumDetail: '/business/doc/curriculum/findById',
    saveCurriculum: '/business/doc/curriculum/save',
    editCurriculum: '/business/doc/curriculum/update',
    removeCurriculum: '/business/doc/curriculum/delete',
    saveCourseware: '/business/doc/curriculum/saveDetails',
    removeCourseware: '/business/doc/curriculum/deleteDetail',
    curriculumDeparts: '/business/doc/curriculum/queryAllDeparts',
    curriculumType: '/business/doc/curriculum/queryStages',
    curriculumUpload: '/business/doc/curriculum/uploadFile?requireDomain=1',
    curriculumVideo: '/business/doc/curriculum/mergeFile',
    // 课程计划
    planList: '/business/task/curriculum/queryList',
    planDetail: '/business/task/curriculum/findById',
    allCompulsory: '/business/task/curriculum/queryAllList',
    planDeparts: '/business/task/curriculum/queryAllDeparts',

    // ***** 分险控制 *****
    // 客户投诉
    complaintList: '/basedata/risk/complain/queryList',
    complaintDetail: '/basedata/risk/complain/findById',
    saveComplaint: '/basedata/risk/complain/save',
    removeComplaint: '/basedata/risk/complain/delete',
    complainCompanies: '/basedata/risk/complain/queryCompanies',
    complainEmployees: '/basedata/risk/complain/queryAllEmployees',
    complainType: '/basedata/risk/complain/queryComplainTypes',
    complainUpload: '/basedata/risk/complain/uploadFile?requireDomain=1',
    saveDealLog: '/basedata/risk/complain/saveDealLog',
    complaintCustomer: '/basedata/risk/complain/findCustomer',
    queryMergeComplains: '/basedata/risk/complain/queryMergeComplains',
    exportMergeComplains: '/basedata/risk/complain/exportMergeComplains',
    // 客户催单
    reminderList: '/business/reminder/queryList',
    reminderDetail: '/business/reminder/findById',
    editReminder: '/business/reminder/update',
    removeReminder: '/business/reminder/delete',
    reminderUpload: '/business/reminder/upload/image?requireDomain=1',
    reminderSales: '/business/reminder/salers',
    reminderClue: '/business/reminder/clueList',
    queryMergeReminder: '/business/reminder/queryMergeReminder',
    exportMergeReminder: '/business/reminder/exportMergeReminder',
    // 敏感词管理
    sensitiveList: '/basedata/sys/sensitive/queryList',
    sensitiveDetail: '/basedata/sys/sensitive/findById',
    saveSensitive: '/basedata/sys/sensitive/save',
    removeSensitive: '/basedata/sys/sensitive/delete',
    sensitiveEmployees: '/basedata/sys/sensitive/queryPageEmployees',
    sensitiveDeparts: '/basedata/sys/sensitive/queryAllDeparts',
    sensitiveParents: '/basedata/sys/sensitive/queryAllParents',
    // 客户联系违规记录
    riskList: '/basedata/risk/violationsCustomer/queryList',
    mistakeList: '/basedata/risk/violationsCustomer/queryMistakeList',
    mistake: '/basedata/risk/violationsCustomer/mistake',
    queryMergeViolations: '/basedata/risk/violationsCustomer/queryMergeViolations',
    exportMergeViolations: '/basedata/risk/violationsCustomer/exportMergeViolations',

    // ***** 任务和活动 *****
    // 营销活动
    activityList: '/business/market/activity/queryList',
    activityDetail: '/business/market/activity/findById',
    saveActivity: '/business/market/activity/save',
    editActivity: '/business/market/activity/update',
    removeActivity: '/business/market/activity/delete',
    activityType: '/business/market/activity/types',
    activityStore: '/business/market/activity/storeList',
    activityUpload: '/business/market/activity/upload/image?requireDomain=1',
    // 销售排行
    taskList: '/business/market/activity/taskList',
    exportRangeList: '/business/market/activity/exportRangeList',
    queryAllActivityList: '/business/market/activity/queryAllList',
    querySaleList:'/basedata/view/sale/querySaleList', //销售排行榜新
    // 裂变活动
    fissionList: '/business/division/activity/queryList',
    fissionDetail: '/business/division/activity/findById',
    saveFission: '/business/division/activity/save',
    editFission: '/business/division/activity/update',
    removeFission: '/business/division/activity/delete',
    fissionUpload: '/business/division/activity/upload/image?requireDomain=1',
    // 裂变任务
    fissionTask: '/business/division/task/queryList',
    queryAllDivisionList: '/business/division/task/queryAllList',
    fissionTaskDetail: '/business/division/task/findById',
    // 客户sop任务
    sopResult: '/basedata/sys/sopResult/customer/queryList',
    sopResultDetail: '/basedata/sys/sopResult/customer/findById',

    // ***** 小程序 *****
    // 首页设置
    businesses: '/basedata/server/portal/queryList',
    productsList: '/basedata/server/portal/queryProducts',
    casesList: '/basedata/server/portal/querySaleCases',
    enterpriseList: '/basedata/server/portal/queryEnterpriseList',
    saveHome: '/basedata/server/portal/save',
    removeHome: '/basedata/server/portal/delete',
    homeUpload: '/basedata/server/portal/uploadFile?requireDomain=1',
    // 基础信息
    basicList: '/basedata/server/companyBaseDoc/queryBaseDocByType',
    basicUploadImg: '/basedata/server/companyBaseDoc/uploadImage?requireDomain=1',
    saveBasic: '/basedata/server/companyBaseDoc/savePromotion',
    removeBasic: '/basedata/server/companyBaseDoc/deletePromotion',
    // 评分模板
    evaluateList: '/basedata/server/scoreTemplate/queryTemplate',
    addEvaluate: '/basedata/server/scoreTemplate/save',
    // 分类
    categories: '/basedata/server/news/queryAllCategories',
    // 使用指南
    manualList: '/basedata/server/news/queryList',
    addManual: '/basedata/server/news/save',
    removeManual: '/basedata/server/news/delete',
    detailManual: '/basedata/server/news/findById',
    // 上传图片
    newsUploadImg: '/basedata/server/news/uploadImage?requireDomain=1',
    // 小程序客服
    servicesList: '/basedata/server/customerService/queryCustomerServices',
    saveServices: '/basedata/server/customerService/save',
    servicesDetail: '/basedata/server/customerService/findById',
    servicesUpload: '/basedata/server/customerService/uploadImage?requireDomain=1',

    // ***** 系统管理 ****
    // 字典管理
    dictionaryList: '/basedata/sys/dictionary/queryDictionary',
    dictionaryType: '/basedata/sys/dictionary/queryDictionaryType',
    saveDictionAry: '/basedata/sys/dictionary/saveDictionAry',
    removeDictionAry: '/basedata/sys/dictionary/delete',
    // 员工管理
    organizationList: '/basedata/sys/employee/queryAllDeparts',
    staffList: '/basedata/sys/employee/queryPageEmployees',
    allStaff: '/basedata/sys/employee/queryAllEmployees',
    saveManager: '/basedata/sys/employee/saveDepartManager',
    AllRoles: '/basedata/sys/employee/queryAllRoles',
    bindRole: '/basedata/sys/employee/bindRole',
    refreshContract: '/basedata/sys/employee/refreshContract',
    updatePhoneNumber: '/basedata/sys/employee/updatePhoneNumber',
    frozen: '/basedata/sys/employee/frozen',
    unfrozen: '/basedata/sys/employee/unfrozen',
    staffCode: '/basedata/sys/employee/getQrCode',
    cancelRight: '/basedata/sys/employee/cancelRight',
    activeStaff: '/basedata/sys/employee/active',
    SuperAdminList: '/basedata/sys/employee/queryPageSuperAdmin',
    saveSuperAdmin: '/basedata/sys/employee/saveSuperAdmin',
    employeeDataAccess: '/basedata/sys/employee/employeeDataAccess',
    findEmployeeDataAccess: '/basedata/sys/employee/findEmployeeDataAccess',
    employeeAllStores: '/basedata/sys/employee/queryAllStores',
    // 商品管理
    goodsList: '/basedata/server/product/queryProducts',
    saveGoods: '/basedata/server/product/saveProduct',
    removeGoods: '/basedata/server/product/delete',
    goodsDetail: '/basedata/server/product/findProductById',
    goodsUpload: '/basedata/server/product/uploadFile?requireDomain=1',
    goodsDictionaries: '/basedata/server/product/allProductDictionaries',
    // ERP商品
    erpList: '/basedata/server/product/queryErpProductList',
    erpDetail: '/basedata/server/product/findErpProductById',
    saveErp: '/basedata/server/product/saveErpProduct',
    // 角色管理
    roleList: '/basedata/sys/role/queryList',
    saveRole: '/basedata/sys/role/save',
    grantAccessRight: '/basedata/sys/role/grantAccessRight2',
    allModules: '/basedata/sys/role/queryAllModules',
    menuByRole: '/basedata/sys/role/getMenuByRole',
    removeRole: '/basedata/sys/role/delete',
    // 系统配置
    findSetting: '/basedata/sys/setting/findSetting',
    saveSetting: '/basedata/sys/setting/saveSetting',
    setStoreRoles: '/basedata/sys/setting/queryStoreRoles',
    setAllDeparts: '/basedata/sys/setting/queryAllDeparts',
    settingUpload: '/basedata/sys/setting/uploadImage?requireDomain=1',
    // 好评库
    commentList: '/business/sale/comment/queryList',
    commentDetail: '/business/sale/comment/findById',
    saveComment: '/business/sale/comment/save',
    editComment: '/business/sale/comment/update',
    removeComment: '/business/sale/comment/delete',
    // 手册
    roleDoc: '/basedata/sys/role/queryDocByRole',
    saveDoc: '/basedata/sys/role/saveRoleDoc',
    removeDoc: '/basedata/sys/role/deleteRoleDoc',
    // 操作日志
    PageEmployees: '/basedata/sys/accessLog/queryPageEmployees',
    PageLogs: '/basedata/sys/accessLog/queryPageLogs',
    // 导出统计结果
    exportEmployeeStatistic: '/basedata/sys/accessLog/exportEmployeeStatistic',
    // 导出操作记录
    exportAccessLogList: '/basedata/sys/accessLog/exportAccessLogList',
    logStores: '/basedata/sys/accessLog/queryAllStores',
    // 客户sop规则
    sopRule: '/basedata/sys/sopRule/customer/queryRules',
    mergeDataQuery: '/basedata/sys/sopRule/customer/mergeDataQuery',
    saveSopRule: '/basedata/sys/sopRule/customer/save',
    removeSopRule: '/basedata/sys/sopRule/customer/delete',
    customerDics: '/basedata/sys/sopRule/customer/queryCustomerDics',
    sopStores: '/basedata/sys/sopRule/customer/queryAllStores',
    sopRuleDetail: '/basedata/sys/sopRule/customer/findById',
    queryAllEmployees: '/basedata/sys/sopRule/customer/queryAllEmployees',
    // 客户sop任务
    querySopList: '/basedata/sys/sopResult/customer/querySopList',
    queryActions: '/basedata/sys/sopResult/customer/queryActions',



    // ***** 统计报表 ****
    // 销售概览
    queryEmployeeDealSum: '/basedata/view/sale/queryEmployeeDealSum', //销售排行榜
    sumAllCustomers: '/basedata/view/sale/sumAllCustomers', //统计客户总数
    queryCustomerAdd: '/basedata/view/sale/queryCustomerAdd', //统计客户数增量
    sumDeals: '/basedata/view/sale/sumDeals', //成交订单数据
    // 销售概览数据
    querySaleViews: '/basedata/view/sale/querySaleViews',
    queryRank: '/basedata/view/sale/queryRank',
    countSeaClues: "/basedata/view/sale/countSeaClues",
    // 跟进汇总
    queryStoreCount: '/basedata/view/follow/queryStoreCount',
    queryViewCount: '/basedata/view/follow/queryViewCount',
    queryStoreEmployeeCount: '/basedata/view/follow/queryStoreEmployeeCount',
    countByDays: "/basedata/view/follow/countByDays",// 按天进行数据汇总显示
    countByStores: "/basedata/view/follow/countByStores", // 按门店进行数据汇总显示
    //转化漏斗
    queryScales: '/basedata/view/transfer/queryScales',
    //员工
    findEmployeeCount: '/basedata/view/employee/findEmployeeCount', //客户概况统计
    employeeQueryCustomerAdd: '/basedata/view/employee/queryCustomerAdd', //客户概况统计
    queryCategories: '/basedata/view/employee/queryCategories', //客户旅程状态对应的字典
    queryCustomerList: '/basedata/view/employee/queryCustomerList', //销售阶段客户数及客户列表
    queryCustomerOrderCount: '/basedata/view/employee/queryCustomerOrderCount', //员工报表
    queryOrderCount: '/basedata/view/employee/queryOrderCount', //统计订单数据
    queryEmployeeOrderCount: '/basedata/view/employee/queryEmployeeOrderCount',
    queryPageClue: '/basedata/view/employee/queryPageClue',
    // 内容营销
    queryArticleById: '/basedata/view/marketing/queryArticleById', //查询内容排行榜(按内容统计)
    queryEmployeeByDate: '/basedata/view/marketing/queryEmployeeByDate', //查询员工数据(按天统计)
    queryEmployeeById: '/basedata/view/marketing/queryEmployeeById', //查询员工排行(按员工统计)
    queryMarkingData: '/basedata/view/marketing/queryMarkingData', //查询员工排行(按员工统计),排序功能
    queryTodayCount: '/basedata/view/marketing/queryTodayCount', //统计当天的营销数据

    // ***** 抽奖活动 ****
    lotteryList: '/business/lucky/activity/queryManageList', //抽奖活动列表
    lotteryDetail: '/business/lucky/activity/findById',
    lotteryRemove: '/business/lucky/activity/delete',
    lotterySave: '/business/lucky/activity/save',
    lotteryEdit: '/business/lucky/activity/update',
    lotteryImg: '/business/lucky/activity/upload/image?requireDomain=1',
    winningList: '/business/lucky/activity/recordList',
    allActivity: '/business/lucky/activity/queryAllList',
    queryAllPrizes: '/business/lucky/activity/queryAllPrizes',
    queryNotBindMarket: '/business/lucky/activity/queryNotBindMarket',// 没有关联抽奖的营销活动列表

    analyseList: '/business/lucky/activity/drawPage',
    analysis: '/business/lucky/activity/analysis',
    verification: '/business/lucky/activity/verification',
    addRemark: '/business/lucky/activity/addRemark',

    // 活动任务
    taskRemove: '/business/task/package/delete', //*
    taskFindById: '/business/task/package/findById', //*
    taskQueryList: '/business/task/package/queryList', //*
    taskSave: '/business/task/package/save', //*
    taskSplit: '/business/task/package/split', //*
    taskSplitPage: '/business/task/package/splitPage', //
    taskSplitResult: '/business/task/package/splitResult', //*
    storeSplit: '/business/task/package/storeSplit', //*
    taskUpdate: '/business/task/package/update', //*
    employees: '/business/task/package/employees', //*
    channelEnum: '/business/task/package/channelEnum',
    rolesList: '/business/task/package/roles',

    // 试卷
    findQuestion: '/business/doc/curriculum/findQuestion',
    saveQuestion: '/business/doc/curriculum/saveQuestion',

    //
    queryViolationsCategory: '/basedata/risk/violationsCustomer/queryViolationsCategory',
}

export default URL
