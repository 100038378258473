<template>
  <!-- 时间段选择组件，选择一个时间段，默认时间是一个月内 -->
  <div style="display: inline-block">
    <el-date-picker v-model="dateTime" size="small" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDate" :picker-options="pickerOptions"
                    style="width: 100%"
    ></el-date-picker>
  </div>

</template>

<script>
import moment from "moment";
export default {
  props: {
    startTime: {default:
        "2023-01-01"
      },
    endTime: {default:
        "2023-01-05"
    }
  },
  components: {

  },
  data() {
    return {
      dateTime: [],//时间节点
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
        // 限制预约时间
        disabledDate(time) {
          if (time.getTime() > Date.now()) {
            return time.getTime() > Date.now()
          }
          if (Date.parse('2022-08-01 00:00:00') > time.getTime()) {
            return Date.parse('2022-08-01 00:00:00') > time.getTime()
          }
        },
      },
    };
  },
  created() {
    this.dateTime = [this.startTime, this.endTime];
  },
  mounted() {
  },
  methods: {
    changeDate(e) {
      if (e[1].endsWith("00:00:00")) {
        e[1] = e[1].substr(0, 10) + " 23:59:59";
      }
      this.$emit("changeDate", e);
    },
    getDate() {
      return this.dateTime;
    }
  },
};
</script>

<style lang="less" scoped>
</style>
