<template>
  <div class="bg-box">
    <!-- <img src="../assets/images/bg-logo.png" alt="" /> -->
    <h1 class="title">{{systemName}}</h1>
    <div class="login">
      <h3>欢迎登录</h3>
      <h5>welcome to login</h5>
      <el-form
        label-width="0px"
        class="login_form"
        :model="loginForm"
        :rules="loginFormRules"
        ref="loginFormRef"
      >
        <el-form-item prop="userAccount">
          <el-input
            prefix-icon="el-icon-mobile-phone"
            v-model="loginForm.userAccount"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="userPassword">
          <el-input
            prefix-icon="el-icon-lock"
            v-model="loginForm.userPassword"
            show-password
            placeholder="请输入密码"
            @keyup.enter.native="login"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login" class="btn">登录</el-button>
          <!-- <el-button @click="loginFormRef">重置</el-button> -->
          <div class="forget" @click="$router.push('/forget-password')">
            <i class="el-icon-question"></i>
            <span>忘记密码</span>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog :visible.sync="showMessage" title="到期提醒" :close-on-click-modal="false" @close="toHome" append-to-body>
      <span class="user-valid-message" v-if="dateCount < 0">您的账户已过期，可能无法正常使用系统，请联系管理人员进行续费</span>
      <span class="user-valid-message" v-else>
        <span>您的账户将在</span>
        <span class="red-word">{{dateCount}}</span>
        <span>天后到期，到期后将不能正常使用系统，请联系管理人员进行续费</span>
      </span>

    </el-dialog>
  </div>
</template>

<script>
import { jsonp } from "vue-jsonp";
import util from "@/util/util";
import config from "@/assets/config";
import pageChangeUtil from "@/util/pageChangeUtil";
export default {
  data() {
    return {
      // 表单绑定
      loginForm: {
        userAccount: "",
        userPassword: "",
        // jzspq1w2e3r4
      },
      systemName: "",
      // 表单验证规则
      loginFormRules: {
        userAccount: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        userPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
      showMessage: false,
      dateCount: 0,
    };
  },
  created() {
    this.systemName = config.systemName;
    pageChangeUtil.clear();
  },
  methods: {
    // 重置表单
    loginFormRef() {
      this.$refs.loginFormRef.resetFields();
    },
    // 跳转到首页
    toHome() {
      this.$router.push("/home");
    },
    // 登录
    login() {
      // 表单预验证
      this.$refs.loginFormRef.validate(async (valid) => {
        // 返回一个布尔值，判断表单规则
        if (!valid) return;
        const { data: res } = await this.$http.post(
          this.URL.login,
          this.loginForm
        );
        util.clear();
        if (res.code != 2000) return this.$message.error(res.message);
        this.$message.success("登录成功！");
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userData", JSON.stringify(res.data));

        if (res.data.warnMessage) {
          this.showMessage = true;
          this.dateCount = Math.ceil((new Date(res.data.validEnd) - new Date()) / (24 * 3600 * 1000));

        } else {
          this.$router.push("/home");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bg-box {
  background: url("../assets/images/bg.png") no-repeat;
  background-size: cover;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  min-width: 1366px;
  height: 100%;
  background-color: #1780b8;
  img {
    width: 700px;
    position: absolute;
    top: 50%;
    right: 52%;
    transform: translateY(-50%);
  }
  .title {
    position: absolute;
    top: 5%;
    left: 50px;
    color: #fff;
  }
  .login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 580px;
    min-height: 530px;
    padding: 30px 50px 40px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 30px;
    h3 {
      text-align: center;
      font-size: 26px;
    }
    h5 {
      text-align: center;
      font-size: 24px;
      color: #999;
      margin-top: 0px;
      font-weight: normal;
      margin-bottom: 60px;
    }
    /deep/.el-input__icon {
      font-size: 20px;
    }
    .login_form {
      width: 80%;
      margin: 0 auto;
      .btn {
        width: 100%;
        // background-color: #b8171a;
        border: none;
        margin-top: 10px;
      }
    }
    /deep/.el-input--prefix .el-input__inner {
      padding-left: 40px;
    }
    .forget {
      cursor: pointer;
      text-align: right;
      margin-top: 10px;
      i {
        font-size: 18px;
        margin-right: 5px;
      }
    }
  }
}
.user-valid-message{
  font-size: 14px;
  .red-word{
    color: red;
    font-size: 22px;
  }
}
</style>
